.info {
  display: flex;
  flex-flow: column nowrap;
  max-width: 600px;
  margin: 40px auto;
  text-align: center;
}

.nav {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 0 -8px;

  > * {
    margin: 8px;
  }

  .propertyAddress {
    font-weight: 600px;
  }
}

.grids {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0 -8px;

  > .column {
    margin: 8px;

    > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.fields {
  border: solid 1px #333;
  font-size: 14px;

  .field {
    display: grid;

    &.facts { grid-template-columns: 160px 120px; }
    &.comparison { grid-template-columns: 160px 120px 120px; }
    &.estimate {
      grid-template-columns: 160px 248px;
      .fieldInput {
        padding: 8px;
        align-self: center;
        justify-self: end;

        :global(.ant-input) {
          width: 60px;
        }

        .bankabilityRadioGroup {
          text-align: center;
          :global(.ant-radio-button-wrapper) {
            width: 60px;
          }
        }
      }
    }

    &:first-child {
      font-weight: 600;
    }

    &:nth-child(even) {
      background-color: #eee;
    }

    .fieldLabel {
      padding: 8px;
      align-self: center;
    }

    .fieldValue {
      padding: 8px;
      align-self: center;
    }
  }

  .formSubmit {
    padding: 8px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    .formError {
      color: #FF6666;
    }
  }
}
