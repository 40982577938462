.tiles {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.tile {
  border: 1px solid black;
  margin: 12px;
  padding: 24px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  &.selected {
    border: 1px solid #993333;
    .planName {
      color: #993333;
    }
  }
}

.planName {
  font-weight: 500;
}

.button {
  margin-top: 8px;
}
