.container {
  display: flex;
  flex: 1;
  height: 100%;
}

.section {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.glitch {
  color: black;
  font-size: 100px;
  position: relative;
  text-align: center;
  margin: 0 auto;
}

@keyframes noise-anim {
  0% {
    clip: rect(10px, 9999px, 50px, 0);
  }

  5% {
    clip: rect(11px, 9999px, 42px, 0);
  }

  10% {
    clip: rect(86px, 9999px, 70px, 0);
  }

  15% {
    clip: rect(8px, 9999px, 7px, 0);
  }

  20% {
    clip: rect(68px, 9999px, 100px, 0);
  }

  25% {
    clip: rect(5px, 9999px, 1px, 0);
  }

  30% {
    clip: rect(24px, 9999px, 83px, 0);
  }

  35% {
    clip: rect(24px, 9999px, 62px, 0);
  }

  40% {
    clip: rect(94px, 9999px, 31px, 0);
  }

  45% {
    clip: rect(30px, 9999px, 2px, 0);
  }

  50% {
    clip: rect(29px, 9999px, 71px, 0);
  }

  55% {
    clip: rect(61px, 9999px, 19px, 0);
  }

  60% {
    clip: rect(59px, 9999px, 11px, 0);
  }

  65% {
    clip: rect(20px, 9999px, 58px, 0);
  }

  70% {
    clip: rect(81px, 9999px, 85px, 0);
  }

  75% {
    clip: rect(97px, 9999px, 91px, 0);
  }

  80% {
    clip: rect(3px, 9999px, 8px, 0);
  }

  85% {
    clip: rect(58px, 9999px, 39px, 0);
  }

  90% {
    clip: rect(96px, 9999px, 19px, 0);
  }

  95% {
    clip: rect(30px, 9999px, 57px, 0);
  }

  100% {
    clip: rect(67px, 9999px, 28px, 0);
  }
}

.glitch:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 red;
  top: 0;
  color: black;
  background: transparent;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

@keyframes noise-anim-2 {
  0% {
    clip: rect(26px, 9999px, 92px, 0);
  }

  5% {
    clip: rect(89px, 9999px, 78px, 0);
  }

  10% {
    clip: rect(13px, 9999px, 88px, 0);
  }

  15% {
    clip: rect(39px, 9999px, 83px, 0);
  }

  20% {
    clip: rect(13px, 9999px, 89px, 0);
  }

  25% {
    clip: rect(34px, 9999px, 70px, 0);
  }

  30% {
    clip: rect(95px, 9999px, 58px, 0);
  }

  35% {
    clip: rect(4px, 9999px, 29px, 0);
  }

  40% {
    clip: rect(71px, 9999px, 9px, 0);
  }

  45% {
    clip: rect(38px, 9999px, 22px, 0);
  }

  50% {
    clip: rect(60px, 9999px, 92px, 0);
  }

  55% {
    clip: rect(81px, 9999px, 59px, 0);
  }

  60% {
    clip: rect(78px, 9999px, 33px, 0);
  }

  65% {
    clip: rect(86px, 9999px, 15px, 0);
  }

  70% {
    clip: rect(97px, 9999px, 27px, 0);
  }

  75% {
    clip: rect(27px, 9999px, 5px, 0);
  }

  80% {
    clip: rect(94px, 9999px, 78px, 0);
  }

  85% {
    clip: rect(78px, 9999px, 43px, 0);
  }

  90% {
    clip: rect(54px, 9999px, 23px, 0);
  }

  95% {
    clip: rect(77px, 9999px, 63px, 0);
  }

  100% {
    clip: rect(73px, 9999px, 23px, 0);
  }
}

.glitch:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 blue;
  top: 0;
  color: black;
  background: transparent;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}
