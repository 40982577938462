@import 'variables';

.layout {
  max-width: 900px;
  margin: 40px auto;
}

.financingTypeSections {
  margin-top: 32px;
}

.financingTypeLayout {
  border: 1px solid $c-heavy-border;
  border-radius: 6px;
  margin-bottom: 32px;

  .financingTypeHeader {
    padding: 8px;
    border-bottom: 1px solid $c-light-border;
    color: $c-darkest;
  }

  .financingTypeApplications {
    padding: 32px;
  }
}

.quoteLayout {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  border-top: 1px solid $c-light-border;

  &.quoteLayoutAccepted {
    background-color: #ffd3d3;
  }

  &:last-child {
    border-bottom: 1px solid $c-light-border;
  }

  .quoteRow {
    flex: 0 0 300px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .quoteCell {
      flex: 1;
      padding: 4px;
      border-right: 1px solid $c-light-border;
      height: 100%;
      justify-content: center;
      display: flex;
      flex-flow: column nowrap;

      .quoteCellLabel {
        font-size: 11px;
        color: $c-text-label;
      }

      .quoteCellValue {
        font-size: 14px;
        color: $c-text-value;
      }
    }
  }

  .quoteActions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    > * {
      margin: 4px;
    }
  }
}

.lenderQuotesLayout {
  margin-bottom: 16px;

  .lenderQuotesMessage {
    font-size: 14px;
    text-align: right;
  }
}

.financingOfferConditionsHeader {
  font-weight: 600;
  margin-top: 16px;
}
