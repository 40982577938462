@import '../../../styles/variables';

.wacc {
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: center;

  &__close {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);

    button.ant-btn {
      width: 32px !important;
      height: 32px !important;
      padding-right: 0 !important;
    }

  }

  &__container {
    position: absolute;
    height: calc(100% + 142px);
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 99;
    padding: $gutter/2;
    border: solid 1px #333;
  }

  &__copy {
    font-size: 1rem;
    padding-bottom: $gutter/4;
  }

  &__graph {
    width: 350px;

    &__header {
      font-size: 10px;
      width: 100%;
      text-align: center;
      font-weight: 500;
    }

    &__image {
      height: 210px;
      width: 100%;
    }
  }

  &__subtext {
    font-size: 0.8em;
    padding-bottom: $gutter/3;
  }

  &__title {
    font-size: 1.2rem;
    font-weight: 400;
    padding-bottom: $gutter/4;
  }

  tspan {
    font-size: 0.4rem;
  }
}
