.layout {
  min-height: 100vh;
  overflow: hidden;
  min-width: 100vw;
  display: flex;
  flex-flow: column nowrap;
}

.header {
  height: 64px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 0 32px;
}

.headerNav {
  display: flex;
  align-items: center;
}

.logo {
  margin: 0 24px;
}

.logoImage {
  height: 48px;
}

.content {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}

.menuIcons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 24px;
}

.createButton {
  height: 40px !important;
  padding: 0 16px !important;
  display: flex !important;
  gap: 8px;
  align-items: center;

  i {
    transform: translateY(1px);
  }
}

.responsiveMenu {
  display: none;
  cursor: pointer;
  padding: 16px;

  i {
    color: white;
    font-size: 20px;
  }
}

@media all and (max-width: 575px) {
  .header {
    padding: 0 16px 0 0;
  }

  .menu {
    display: none;
  }

  .responsiveMenu {
    display: block;
  }
}

:global(.theme--ecoamerica) {
  .header {
    background-color: white;
    border-bottom: 4px solid #11AE96;
  }

  .responsiveMenu {
    i {
      color: #11AE96;
    }
  }
}
