@import "../../../styles/variables";

@mixin sidebarContent {
  transition: all 0.3s ease-out;
  opacity: 0;
}

.addressSearch {
  transition: all 0.3s ease-out;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 9;
  opacity: 0;
  pointer-events: none;
  transform: translateY(60px);

  .addressSearchCard {
    width: 100%;
    max-width: 700px;
    background: transparent;

    @media all and (max-width: $sm-breakpoint) {
      max-width: 100%;
      padding-left: $gutter * 1.5;
      padding-right: $gutter * 1.5;
    }
  }

  .addressSearchCardHeader {
    font-variant: petite-caps;
    font-size: 1.85rem;
    text-align: center;
    color: #000000;
    margin-bottom: 10px;

    @media all and (max-width: $sm-breakpoint) {
      font-size: 1.5rem;
    }
  }

  .addressSearchCardSubtext {
    font-size: 16px;
    text-align: center;
    margin-bottom: 8px;
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media all and (max-width: $sm-breakpoint) {
    input {
      font-size: 16px;
    }
  }
}

.addressSearch__active {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0px);
  transition-delay: 0.15s;
}

.checkApproval {
  @include sidebarContent;
}

.containerActions {
  display: flex;
  justify-content: space-between;
}

.containerTitle {
  font-size: 1.5rem;
  color: #000;
}

.mapContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;

  & > div {
    transition: all 0.3s ease-out;
  }
}

.mapContainer__small {
  & > div {
    width: calc(100% - 15vw);

    @media all and (max-width: $lg-breakpoint) {
      width: calc(100% - 35vw);
    }

    @media all and (max-width: $md-breakpoint) {
      width: calc(100% - 55vw);
    }

    @media all and (max-width: $sm-breakpoint) {
      opacity: 0;
    }
  }
}

.mapContainer__medium {
  & > div {
    width: calc(100% - 40vw);
  }
}

.mapContainer__large {
  & > div {
    width: calc(100% - 80vw);
  }
}

.mapContainer__full {
  & > div {
    width: 100%;
  }
}

.overlay {
  transition: all 0.3s ease-out;
  background: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}

.overlay__active {
  opacity: 1;
  pointer-events: auto;
  transition-delay: 0.15s;
}

.quoteContainer {
  position: relative;
  width: calc(100% + #{$gutter});
  height: calc(100vh - 64px);
  display: flex;
  top: 0;
  left: -$gutter / 2;
  flex-direction: column;
  align-items: center;
}

.quoteContainer__card {
  width: 100%;
  max-width: 600px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px rgba(0, 0, 0, 0.55);
}

.quoteContainer__content__active {
  opacity: 1;
  pointer-events: auto;
  transition-delay: 0.1s;
}

.quoteContainer__search__content {
  display: flex;
  flex-direction: column;
}

.quoteContainer__search__card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.sidebar {
  transition: all 0.3s ease-out;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  height: 100%;
  width: 0;
  background: #fff;

  //padding: $gutter;
  z-index: 9;
  box-shadow: 5px 0 20px 0 rgba(0, 0, 0, 0.25);
  overflow: auto;
  display: flex;
  flex-flow: column nowrap;
}

.sidebar__active {
  opacity: 1;
  transform: translateX($gutter / 2);
  transition-delay: 0.05s;
}

.sidebar__content__active {
  opacity: 1;
}

.sidebar__small {
  width: 35vw;

  @media all and (max-width: $lg-breakpoint) {
    width: 50vw;
  }

  @media all and (max-width: $md-breakpoint) {
    width: 70vw;
  }

  @media all and (max-width: $sm-breakpoint) {
    width: 100vw;
  }
}

.sidebar__medium {
  width: 40vw;

  @media all and (max-width: $lg-breakpoint) {
    width: 60vw;
  }

  @media all and (max-width: $md-breakpoint) {
    width: 75vw;
  }

  @media all and (max-width: $sm-breakpoint) {
    width: 100vw;
  }
}

.sidebar__large {
  width: 80vw;

  @media all and (max-width: $lg-breakpoint) {
    width: 100vw;
  }
}

.sidebar__full {
  width: 100vw;
}

.layout {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 0 32px;

  .title {
    font-size: 21px;
    text-align: center;
  }

  .subtitle {
    padding: 8px;
    max-width: 700px;
    text-align: center;

    .error {
      color: #FF6666;
    }

    .success {
      color: #13BF81;
    }
  }

  .location {
    font-weight: 600;
  }

  .actions {
    > * {
      margin: 8px;
    }
  }
}

.ravtiHeader {
  padding: 16px;
  background-color: #c4c9d480;
  max-width: 600px;
  margin: 0px 32px 32px 32px;

  p {
    color: #4E5E7F;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 20px;
  }
}

.banner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 48px;
  z-index: 99;
  background-color: #993333;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

:global(.theme--ecoamerica) {
  .banner {
    background-color: #11AE96;

    a:hover {
      color: white;
    }
  }
}
