.form {
  max-width: 300px;
}

.logo {
  width: 100%;
}

.forgot {
  float: right;
}
