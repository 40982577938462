.layout {
  margin-top: 24px;
  border: 1px solid #666;
  padding: 8px;

  .quotes {
    border-top: 1px solid #666;

    > * {
      &:not(:last-child) {
        border-bottom: 1px solid #999;
      }
    }
  }
}

.quote {
  padding: 4px;
}

.applicantNotifiedAt {
  font-size: 12px;
}
