.avatar {
  cursor: pointer;
  padding: 8px;
  font-size: 20px;
  color: white;
}

.dropdown {
  li.ant-dropdown-menu-item-selected {
    background: red;
  }
  &__item {
    display: flex;
    align-items: center;

    a {
      color: #666;
      width: 100%;
      height: 100%;
      display: block;
      &:hover {
        color: #666;
      }
    }
    i {
      margin-right: 8px;
    }
  }

  &__noIcon {
    width: 100%;
    height: 100%;
    display: block;
    a {
      padding-left: 24px;
      color: #666;
      width: 100%;
      height: 100%;
      display: block;
      &:hover {
        color: #666;
      }
    }
  }

  &__title {
    font-weight: 700;

    i {
      margin-right: 8px;
    }
  }
}

.userWelcome {
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
  font-style: italic;
  transform: translateY(2px);
  height: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

@media all and (max-width: 575px) {
  .userWelcome {
    display: none;
  }
}

:global(.theme--ecoamerica) {
  .avatar {
    color: #11AE96;
  }
}
