.layout {
  width: unset;
  margin: 0 8px;
}

@media all and (min-width: 575px) and (max-width: 1023px) {
  .layout {
    padding: 0 16px;
  }
}

@media all and (min-width: 1024px) {
  .layout {
    width: 768px;
    margin: 0 auto;
  }
}

@media all and (min-width: 1200px) {
  .layout {
    width: 960px;
    margin: 0 auto;
  }
}
