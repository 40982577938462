// sass-lint:disable no-vendor-prefixes
@import './variables';

@mixin contractor-sidebar-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $gutter/2;
  box-sizing: content-box;
}
@mixin contractor-sidebar-container {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
}

@mixin contractor-sidebar-content {
  padding: $gutter/2;
  overflow-x: hidden;
}

@mixin contractor-sidebar-title{
  color: #000;
  font-size: 1.3rem;
  line-height: 1.3;
  margin-bottom:$gutter;
  margin-top: $gutter/2;

  span {
    font-style: italic;
    font-size: 0.75em;
    display: block;
    color: rgba(#000, 0.5);
  }
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color; /* Chrome/Opera/Safari */
  }
  &::-moz-placeholder {
    color: $color; /* Firefox 19+ */
  }
  &:-ms-input-placeholder {
    color: $color; /* IE 10+ */
  }
  &:-moz-placeholder {
    color: $color; /* Firefox 18- */
  }
}

@mixin transition(
  $prop : $transition-prop,
  $duration : $transition-duration,
  $ease : $transition-ease
) {
  transition: $prop $duration $ease;
  backface-visibility: hidden;
}
