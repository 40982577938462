@import '../../../styles/variables';

.layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 40px 0;
}

.tableHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: $gutter/2;

  &__actions {
    margin-left: auto;
    display: flex;
    align-items: center;

  }

  &__favoriteFilter {
    margin-right: $gutter/2;
  }
}

.success {
  path {
    fill: #008000;
  }
}

.error {
  path {
    fill: #ff6700;
  }
}

.projectListMobile {
  display: none;
}

.projectListDesktop {
  margin: 40px;
  position: relative;

  .projects {
    width: 100%;
  }

  &__favoriteRowButton {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
  }

  .spinnerContainer {
    text-align: center;
    margin: 0 auto 40px 0;
  }
}

.benefits {
  margin: 16px auto auto auto;

  a {
    margin-right: 24px;
  }
}

@media all and (max-width: 767px) {
  .projectListDesktop {
    display: none;
  }

  .projectListMobile {
    display: flex;
  }

  .benefits {
    margin: 16px 8px;
  }
}
