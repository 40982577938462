@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.form {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.formActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $gutter/1.5 52px $gutter/1.5 28px;
  position: relative;
  z-index: 999;
  background-color: #fff;
  border-top: 1px solid #ececec;

  &> div {
    margin-bottom: 0;
  }
}

.formActions__continue {
  display: flex;

  &> div {
    margin-left: $gutter;
    margin-bottom: 0;
  }
}

.formContent {
  @include transition;
  flex: 1;
  overflow: auto;
  padding: $gutter 40px 0 $gutter;

  :global {
    animation: fadeIn 0.4s;
  }
}

.formTitle {
  font-size: 2rem;
  display: block;
  color: #000;
  margin-bottom: 30px;

  span {
    font-size: 0.6em;
    display: block;
  }

  &__error {
    color: #cc0000;
  }
}

.hideLabel {
  label {
    opacity: 0;
    pointer-events: none;
  }
}

.progressBar {
  width: 100%;
  position: relative;
  margin-bottom: $gutter;
  padding-bottom: $gutter;
  display: flex;
  justify-content: space-evenly;
  border-bottom: solid 1px #eee;

  @media all and (max-width: $sm-breakpoint) {
    &> div:nth-child(even) {
      display: none;
    }
  }
}

.progressBar__item {
  @include transition;
  display: flex;
  opacity: 0.25;
  align-items: center;
}

.progressBar__item__active {
  opacity: 1;
}

.progressBar__item__number {
  @include transition;
  border: solid 1px #333;
  margin-right: $gutter/2;
  border-radius: 50%;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
}

.progressBar__item__number__filled {
  background: $brand-primary;
  color: $brand-primary-opposite;
  border-color: $brand-primary
}

.stepComponentContainer {
  @include transition;
  position: relative;

  &> div {
    @include transition;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-bottom: 5px;
  }
}

.stepComponentContainer__current {
  transition-delay: 1s;
  transform: translateX(0);
  opacity: 1;
}

.stepComponentContainer__next {
  transform: translateX(500px);
  opacity: 0;
}

.stepComponentContainer__previous {
  transform: translateX(-500px);
  opacity: 0;
}

.title {
  margin-top: 30px;
}

.threeColumn {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;

  @media all and (max-width: $sm-breakpoint) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 0;
  }
}

.twoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  @media all and (max-width: 575px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0;
  }
}

@media all and (max-width: 575px) {
  .formContent {
    padding: 8px;
  }

  .formTitle {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .progressBar {
    margin-bottom: 16px;
    padding-bottom: 16px;
  }

  .progressBar__item {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    max-width: 100px;
  }

  .progressBar__item__number {
    margin-right: 0;
  }

  .progressBar__item__label {
    text-align: center;
  }

  .formActions {
    padding: 8px;
  }

  .formActions__continue {
    & > div {
      margin-left: 8px;
    }
  }
}
