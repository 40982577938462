.layout {
  flex: 1;
  background: whitesmoke;
  border-radius: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 16px;

  i {
    color: #FFBA5B;
  }

  button {
    margin-left: 16px;
  }
}
