:root {
  --maroon: #993333;
}

.criteria {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.criteriaContent {
  position: relative;
  z-index: 1;
}

.criteriaHeading {
  margin-bottom: 16px;
}

.criteriaHeadingRange {
  font-size: 16px !important;
  line-height: 22px !important;
  letter-spacing: normal !important;
}

.criteriaHeadingAverage {
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: normal !important;
}

.criteria .criteriaActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chartWrapper {
  position: relative;

  .slider {
    position: absolute;
    bottom: -10px;
    z-index: 11;
  }
}

.criteria {
  :global {
    .rc-slider-track {
      background-color: var(--maroon) !important;
    }
  }
}

:global {
  .rc-slider-handle, .rc-slider-handle:hover, .rc-slider-handle:active {
    margin-left: -15px;
    margin-top: -13px;
    width: 30px;
    height: 30px;
    border-color: var(--maroon) !important;
    box-shadow: 0 0 5px var(--maroon) !important;
  }
}
