.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: center;
}

.title {
  font-size: 24px;
  padding: 16px;
}
