@import '../../../styles/variables';
@import '../../../styles/mixins';

.layout {
  width: 100%;
  border: solid 1px #333;
  background: #fff;
  margin-bottom: $gutter;

  &__headline {
    background: #4A772F;
    color: #fff;
    font-weight: 700;
    padding-left: 10px !important;
  }

  &__subHeadline {
    background: #DEECD5;
    font-weight: 700;
    padding-left: ($gutter / 2 + 10) !important;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 100px 60px;
    grid-gap: 10px;
    align-items: center;
    padding: $gutter / 4;
    padding-left: $gutter + 10;
    border-bottom: solid 1px #bbb;
    font-size: 0.9rem;

    &__header {
      font-weight: 700;
      font-size: 0.8rem;
    }

    div:last-child {
      text-align: right;
    }
  }
}
