@import '../../../styles/variables';

.getConnected {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    grid-gap: $gutter * 2;
    margin-top: $gutter*2;

    &__item {
      text-align: center;

      &:first-child {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 20%;
          height: 60%;
          right: -$gutter;
          width: 1px;
          background-color: #666;
        }
      }

      &__actions {
        max-width: 350px;
        margin: $gutter auto;
      }
    }
  }

  &__title {
    font-size: 2rem;
    text-align: center;

    span {
      font-size: 0.6em;
      display: block;
    }
  }
}
