@import '../../../styles/variables';

.content {
  padding: $gutter / 3;

  &__actions {
    a {
      margin-left: $gutter/2;
    }
  }
}
