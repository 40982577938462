.upHotApplication {
  display: flex;
  color: #cc0000;
  align-items: center;

  svg {
    display: inline;
    width: 20px;
    height: 20px;

    path {
      fill: #CC0000;
    }
  }
}
