$pinSize: 48px;
$pinSizeSmall: 32px;

.header {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding-top: $pinSize + 16;
  margin-top: -($pinSize + 16);
  margin-bottom: 48px;
}

.pin {
  width: $pinSize;
  height: $pinSize;
  border-radius: 50% 50% 50% 0;
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  transform: rotate(-45deg);
  top: 0;

  &:after {
    content: ' ';
    width: $pinSize / 2;
    height: $pinSize / 2;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}

.title {
  font-size: 32px;
  color: #000;
}

@media all and (max-width: 900px) {
  .header {
    max-width: 450px;
    text-align: center;
    padding-top: $pinSizeSmall + 16;
    margin-top: -($pinSizeSmall + 16);
    margin-bottom: 24px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .pin {
    width: $pinSizeSmall;
    height: $pinSizeSmall;

    &:after {
      width: $pinSizeSmall / 2;
      height: $pinSizeSmall / 2;
    }
  }

  .title {
    font-size: 24px;
    line-height: 24px;
  }
}
