@import '../../../styles/variables';
@import '../../../styles/mixins';

.application {
  padding: 32px;

  &__actions {
    display: flex;
    margin-bottom: $gutter;
    align-items: flex-end;
  }

  &__back {
    display: block;
    font-size: 14px;
    margin-bottom: 15px;
  }

  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: 450px 1fr;
    grid-gap: $gutter;
  }

  &__goNoGo {
    background-color: #fff;
    border: solid 1px #666;
    margin-bottom: 30px;
    padding: 15px;

    &__failed span {
      color: #cc0000
    }

    &__passed span {
      color: #4A772F
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__inputs {
    font-size: 0.8rem;
    background: #fff;
    border: solid 1px #333;

    &__row {
      display: grid;
      grid-template-columns: 1fr 200px 100px 200px 200px;
      grid-gap: 10px;
      align-items: center;

      &__contractor {
        grid-template-columns: 1fr 300px
      }

      &__header {
        i {
          margin-left: 4px;
        }
      }

      &> div {
        @include transition;
        padding: $gutter/3;
        opacity: 1;
      }

      &:nth-child(even) {
        background-color: #eee;
      }
    }
  }

  &__loading {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
  }

  &__title {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: $gutter;

    .applicationSubtitle {
      display: block;
      font-size: 0.8em;
      font-style: italic;
    }
  }

  &__dirtyField {
    opacity: 0.35 !important;
  }

  &__input {
    max-height: 30px !important;
    font-size: 12px !important;
    padding: 7px !important;
  }

  &__select {

    &> div {
      min-height: 20px !important;
    }
  }

  &__option {
    font-size: 12px;
  }

  &__values {
    &__null {
      color: #ccc;
      font-style: italic;
    }
  }
}
