.root {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
}

.hidden {
  display: none;
}


.impersonateOverlay {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999999;
  border: 2px solid black;
  background: rgba(0, 0, 0, 0.2);
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  .impersonate {
    display: flex;
    flex-flow: column nowrap;

    .impersonateText {
      color: red;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
    }

    .impersonateEmail {
      font-size: 12px;
    }
  }

  i {
    font-size: 24px;
    color: red;
    margin-left: 8px;
    cursor: pointer;
  }
}
