:global {
  .theme--ecoamerica {
    .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
      background: #19939E;
    }

    .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
      background-color: #11AE96;
    }
  }
}
