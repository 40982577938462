.upCard {
  &__title {
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      display: block;
      padding-top: 4px;
      font-size: 0.8em;
      color: #999;
      font-style: italic;
    }
  }
}
