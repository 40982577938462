@import '../../../../styles/variables';

.edrResultsTable {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
    border: solid 1px #666;
    border-top: none;
    border-left: none;
    font-size: 12px;
    background: #fff;

    &> div{
      padding: 5px;
      border-top: solid 1px #666;
      border-left: solid 1px #666;
      width: 100%;
      height: 100%;

      &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13), &:nth-child(17) {
        text-align: left;
      }
    }
  }
}
