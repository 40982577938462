.layout {
  display: flex;
  flex-flow: column nowrap;
}

$timeHeight: 40px;

.subtitle {
  height: $timeHeight;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: #F1F2F2;
  font-size: 16px;
  color: #101010;

  .name {
    padding-left: 16px;
  }

  .time {
    padding-right: 16px;
  }
}

.content {
  flex: 1;
  display: flex;
}
