.hidden {
  display: none;
}

.header {
  border: 1px solid #C4C9D4;
  border-bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  font-weight: 600;
  font-size: 16px;
}

.body {
  border: 1px solid #C4C9D4;
  padding: 16px;
}

.footer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.dots {
  margin: -4px;

  > * {
    font-size: 14px;
    color: #993333;
    margin: 4px;

    &:global(.fal) {
      color: #C4C9D4;
    }
  }
}
