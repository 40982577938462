.layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.contractors {
  text-align: left;

  .contractor {
    padding: 8px;

    .contractorStatus {
      font-style: italic;
    }
  }
}
