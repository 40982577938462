
.layout {
  padding: 40px;
  max-width: 660px;
  margin: 0 auto;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
}

.title {
  font-size: 21px;
  color: #101010;
}

.stats {
  padding-top: 16px;
}

$card-secondary: #404040;
.card {
  border: 1px solid #ccc;
    box-shadow: 1px 1px 0px #ccc;
  border-radius: 4px;
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 180px;
  height: 80px;
  padding: 8px;
  margin: 4px;

  .valueLine {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;

    .value {
      color: #101010;
      font-size: 21px;
    }

    i {
      font-size: 32px;
      color: $card-secondary;
      padding-left: 8px;
    }
  }

  .text {
    font-variant: small-caps;
    color: $card-secondary;
    font-size: 14px;
  }
}
