@import 'variables';

.showAdvanced {
  text-align: right;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.leasedRow, .formRow {
  display: flex;
  > * {
    flex: 1;
    margin: 16px !important;
  }
}

.layout {
  border: 1px solid $c-heavy-border;
  border-radius: 6px;
  margin-top: 32px;
  margin-bottom: 16px;

  .layoutHeader {
    padding: 8px;
    border-bottom: 1px solid $c-light-border;
    color: $c-darkest;
  }

  .layoutContent {
    padding: 8px;
  }
}

.actionErrors {
  font-size: 14px;
  text-align: right;
}

.actions {
  margin: 0 16px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
