@import '../../styles/variables';
.root {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 60px;
    padding-top: 60px;
    border-top: solid 1px #eee;
  }
}

.leftContent {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .checkbox {
    margin-right: 8px;
  }

  .progress {
    margin-right: 8px;
  }

  .map {
    width: 185px;
    height: 185px;
    margin-right: $gutter/2;
    margin-left: $gutter/2;
    border: 1px solid black;
  }
}

.rightContent {
  flex: 1;

  .statsColumns {
    display: flex;
    flex-flow: row nowrap;

    .statsColumn {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
    }
  }
}

.propertyName {
  margin-bottom: 0px;
  display: flex;

  span {
    display: block;
    margin-right: $gutter/2;
  }

}

.rate {
  :global(.ant-rate-star) {
    margin-right: 0px;
  }
}

.map {
  width: 200px;
  height: 200px;
}

.progressText {
  font-size: 0.75em;
}

.detailsCollapse {
  margin-top: 16px;
}

.projectTypeStage {
  text-transform: capitalize;
}

.detailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h4 {
    margin-bottom: 0;
  }

  &__feedback {
    display: flex;
    align-items: center;
    text-indent: 15px;
    padding-bottom: $gutter/2;
  }
}

.detailsContent {
  h5 {
    display: flex;
    align-items: center;

    ul {
      margin-left: 4px;
      transform: scale(0.8) translateY(-4px);
    }
    li {
      margin-right: 4px !important;
    }
  }
  p {
    margin-bottom: $gutter * 1.5;
  }
}

.rating {
  width: 100%;

}
