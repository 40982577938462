.layout {
  flex: 1;
  padding: 8px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
}

.title {
  font-size: 21px;
  color: #101010;
}

.description {
  font-size: 14px;
  color: #101010;
}
