@import '../../../styles/variables';

.layout {
  margin: 40px;
}

.cardTitle {
  color: #999;
  font-style: italic;
  padding-bottom: 3px;
}

.content {
  display: flex;
}

.form {
  margin-bottom: 32px;
  margin-right: 32px;
  max-width: 320px;

  @media (max-width: 575px) {
    width: 320px;
    padding: 0px 16px;

    label {
      white-space: normal;
    }
  }
}

.errorMessage {
  color: #cc0000;
  max-width: 500px;
  text-align: center;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.property {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 24px;
  text-align: center;
  margin-bottom: $gutter/2;
  line-height: 1.3;

  span {
    color: #aaa;
    font-size: 0.8em;
    line-height: 1;
    font-style: italic;
  }
}

.lender {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.quote {
  display: flex;
  flex-flow: column nowrap;
  margin-left: 16px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.lenderQuoteBlock {
  width: 100%;

  table td:hover, table tr:hover td {
    background: #fff !important;
  }

  .bold {
    font-weight: bold;
  }

  .dim {
    opacity: 0.5
  }
}

.quoteMain {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  .block {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    padding: 16px 8px;
    height: 136px;
    border-bottom: 1px solid #993333;

    &.borderRight {
      border-right: 1px solid #993333;
    }
  }

  .header {
    font-size: 22px;
    padding: 8px;
  }

  .subheader {
    font-size: 16px;
  }

  .body {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  .bodyItem {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 8px;

    &:first-child {
      border-right: 1px solid #993333;
    }
  }

  .accept {
    display: flex;
    flex-flow: column nowrap;
    padding: 16px;
    justify-content: center;
    text-align: center;
  }

  @media (max-width: 767px) {
    flex-flow: column nowrap;

    .block {
      &.borderRight {
        border-right: 0;
      }
    }
  }
}

.quoteExtra {
  display: flex;
  flex-flow: column nowrap;

  .quoteExtraLine {
    display: flex;
    flex-flow: row wrap;

    >:not(:first-child) {
      margin-left: 24px;
    }
  }
}
