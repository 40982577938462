@import '../../../styles/variables';
@import '../../../styles/mixins';

@keyframes inAnimation {
  from {
    margin-left: 50px;
    opacity: 0;
  }
  to {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes outAnimation {
  from {
    margin-left: 0;
    opacity: 1;
  }
  to {
    margin-left: -50px;
    opacity: 0;
  }
}

.messageContainer {
  width: 100%;
  height: 60px;
  position: relative;
}
.message {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  margin-left: 50px;
  width: 100%;
  color: $brand-primary;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.message__active {
  animation-name: inAnimation;
  animation-play-state: running;
  animation-delay: 0.15s;
}

.message__waiting {
  animation-name: outAnimation;
  animation-play-state: running;
}
