@import "../../../styles/variables";
@import "../../../styles/mixins";

.contractor {
  flex: 1 0 400px;
  max-width: 400px;
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  padding: 8px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 0px #ccc;
  margin: 8px;
  overflow: hidden;

  .company {
    font-size: 16px;
    font-weight: 600;
  }

  .body {
    display: flex;
    flex-flow: row nowrap;
  }

  .info {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: stretch;

    .address, .contact {
      font-size: 14px;
    }

    .address {
      margin-bottom: 16px;
    }

    .title {
      font-size: 18px;

      .preferred {
        font-size: 14px;
        color: #faad14;
      }

      .distance {
        margin-top: 8px;
        font-size: 0.8em;
        font-weight: 300;
        font-style: italic;
      }
    }
  }

  .mapContainer {
    flex: 0 0 200px;
    margin-right: 16px;

    img {
      width: 100%;
    }
  }
}

@media all and (max-width: 575px) {
  .contractor {
    flex: unset;

    .body {
      align-items: flex-start;

      .mapContainer {
        flex: 0 0 100px;
      }
    }
  }
}
