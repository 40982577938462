@import '../../../../styles/variables';

.mobile {
  display: none;
  margin-bottom: 40px;

  .list {
    display: flex;
    flex-flow: column nowrap;
    margin: 16px;
    flex: 1;
    border: 1px solid $c-light;
  }

  .listItem {
    padding: 8px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid $c-light;
    }

    .body {
      font-size: 14px;

      .status {
        display: flex;
        align-items: center;

        > *:last-child {
          margin-left: 8px;
        }
      }

      .address {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .cta {
      flex: 1;
      text-align: right;
    }
  }
}

@media all and (max-width: 767px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }
}
