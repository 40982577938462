.layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.title {
  font-size: 24px;
  padding: 16px;
  text-align: center;
}
