.layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title {
  font-size: 21px;
  padding-bottom: 16px;
}

.actions {
  padding-top: 16px;

  > * {
    padding: 16px;
  }
}
