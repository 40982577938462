@import '../../../../styles/variables';

.buttonRow {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0 !important;

  div {
    display: flex;
    justify-content: flex-end;
  }
}

.doubleWide {
  flex: 2;
}

.formContainer {
  .formRow:last-child .buttonRow {
    margin-bottom: 0 !important;
  }
}

.formRow {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;

  &> * {
    flex: 1;
    margin: 0 10px 10px 10px !important;
  }

  button {
    width: auto;
  }
}

.preApplicationLayout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  flex: 1;

  .preApplicationTitle {
    font-size: 24px;
  }

  .preApplicationContainer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }

  .preApplicationSubtitle {
    font-size: 16px;
    margin-bottom: 16px;
    text-align: center;
  }
}
