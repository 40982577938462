.detailsContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;

  .detailsTitle {
    font-size: 24px;
  }

  .detailsSubtitle {
    margin-bottom: 48px;
  }
}
