@import '../../../styles/mixins';
@import '../../../styles/variables';

.content {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: $gutter;

  img {
    max-width: 100%;
  }

  hr {
    border: 1px solid #F5F5F5;
    width: 300px;
    text-align: center;
    margin: 24px auto 16px auto;
  }
}

.advancedButton {
  position: absolute;
  bottom: $gutter/2;
  right: $gutter/2;
  width: calc(100% - #{$gutter});
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__error {
    @include transition;
    padding-right: $gutter;
    font-size: 0.8rem;
    color: #cc0000;
    opacity: 0;

    &__showing {
      opacity: 1;
    }
  }

  button {
    margin-left: auto;
  }
}

.calculator {
  overflow: auto;

  &__content {
    padding: 16px;
    position: relative;
  }

  &__fundDisplay {
    width: 100%;
    svg {
      max-width: 100%;
    }
  }

  &__slider {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) 350px;
    height: 50px;
    grid-gap: $gutter;

    &__input {
      display: grid;
      grid-template-columns: 1fr 1fr;
      transform: translateY(10px);
      align-items: center;
      height: 45px;
      grid-gap: $gutter;
    }

    &__label {
      text-align: right;
      font-size: 14px;
    }

    &__value {
      position: relative;
      &:after {
        content: '';
        height: 1px;
        background: #000;
        width: 90%;
        top: 27px;
        left:0;
        display: block;
        position: absolute;
      }

      &__field {
        flex: 1;
      }

      &__inputContainer {
        display: flex;
        height: 45px;

      }

      &__prelabel, &__postLabel {
        width: 100px;
        height: 45px;
      }

      &__isinput {
        &:after {
          display: none;
        }
      }
    }
  }
}

.costTo, .calculator {
  border: solid 1px #333;
  box-shadow: 0 3px 5px rgba(0,0,0,0.15);
}

.costTo {
  position: relative;
  &__content {
    padding: $gutter/3;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 60px;
    justify-items: center;
    grid-row-gap: 5px;


    span {
      display: block;
      text-align: center;
    }
    sup {
      font-size:0.5em;
    }
  }

  &__label {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: 8px;
  }

  &__main {
    grid-column: 1 / 3;
    font-size: 4rem;
    line-height: 1;
    color: #000;

    &:first-child {
      padding-bottom: 20px;
    }

    span {
      font-size: 0.35em;
    }
  }

  &__secondary {
    font-size: 1.1rem;
    color: #333;

    sup {
      font-size:0.6em;
      transform: translate(2px, 1px);
      display: inline-block;
    }
  }

  &__tooltip {
    position: absolute;
    top: 36px;
    right: 8px;
    z-index: 1;

    &> div {
      cursor: pointer;
    }
  }
}

.ownerOccupiedSwitch {
  width: 350px;
  margin-left: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 30px;
  align-items: center;
  margin-top: 15px;
  grid-gap: $gutter;

  div {
    text-align: right;
    font-size: 14px;
  }

  button {
    max-width: 40px;
  }
}

.waccContainer {
  transition: opacity 0.3s ease-out;
  opacity: 0;
  pointer-events: none;

  &__show {
    opacity: 1;
    pointer-events: auto;
  }
}

.header {
  background: #666;
  color: #fff;
  text-align: center;
  padding: 5px $gutter/3;
}

.funding {
  display: grid;
  grid-template-columns: 220px 1fr;
  grid-gap: $gutter/2;
  margin-bottom: $gutter/2;

  &__container {
    position: relative;
  }
}

@media all and (max-width: 400px) {
  .funding {
    grid-template-columns: 1fr;
  }

  .calculator {
    &__slider {
      grid-template-columns: 1fr;
      height: unset;
      grid-gap: unset;

      &:not(:last-child) {
        margin-bottom: 32px;
      }

      &__input {
        transform: unset;
        height: unset;
        order: 1;
      }

      &__control {
        order: 2;
      }

      &__label {
        text-align: unset;
      }

      &__value {
        font-weight: 600;
        text-align: right;

        &:after {
          content: unset;
        }
      }
    }
  }
}

.fundingTypeSelector {
  text-align: center;
  margin-bottom: 24px;
}
