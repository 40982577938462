@import '../../../../styles/variables';

.layout {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto 8px auto;
  padding-bottom: 40px;
  max-width: 1024px;
}

.title {
  font-size: 24px;
  padding: 16px;
  text-align: center;
}

.dataLayout {
  display: flex;
  flex-flow: column nowrap;
}

@media (max-width: 899px) {
  .dataLayout {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
}
