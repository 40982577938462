@import '../../styles/variables';

.root {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: $gutter;
  border-top: 1px solid #C4C9D4;
  padding-top: 16px;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 40px;
  }
}

.infoRow {
  @media all and (max-width: 1100px) {
    flex-direction: column;
  }
}

.leftContent {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .map {
    width: 180px;
    height: 180px;
    margin-right: $gutter/2;
    border: 1px solid black;
  }

  @media all and (max-width: 1100px) {
    margin-top: $gutter/2;
  }
}

.rightContent {
  flex: 1;

  .statsColumns {
    display: flex;
    flex-flow: row nowrap;

    &> div {
      &:last-child {
        flex: 2;
      }
    }

    .statsColumn {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;

      textarea {
        min-height: 125px !important;
        height: 125px !important;
      }
    }
  }
}

.propertyName {
  margin-bottom: 0px;
}

.rate {
  :global(.ant-rate-star) {
    margin-right: 0px;
  }
}

.map {
  width: 200px;
  height: 200px;
  margin-right: $gutter/2;
  margin-left: $gutter/2;
}

.quote {
  display: flex;
  flex-flow: column nowrap;
  margin: 16px;
  width: 100%;
  align-items: flex-start;

  &:last-child {
    margin-bottom: 0px;
  }

  > *:not(:last-child) {
    margin-right: 24px;
  }

  .quoteHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-bottom: solid 1px #f3f3f3;
    margin-bottom: $gutter;
    padding: $gutter/2 0;

    .quoteHeaderLabel {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    .removeQuoteButton {
      margin-left: 16px;
      width: 24px !important;
      height: 24px !important;
    }

    .confirmButton {
      margin-left: $gutter/2;
    }
  }

  .quoteContent {
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 100%;
    grid-gap: $gutter;
  }

  .quoteField {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 8px;

    &> div {
      flex: 1;
      display: flex;
      align-items: center;

      &> div > div > div {
        position: absolute;
        width: 100%;
        font-style: italic;
      }

      &> div:first-child {
        margin-right: $gutter/2;
        min-width: 110px;
        width: 110px;
        text-align: right !important;
      }
      &> div:last-child {
        flex: 1;

        > div > span {
          width: 100%;

          > div {
            width: 100%;
          }
        }
      }
    }
  }

  .quoteBasics {
    flex: 0 0 150px;
    display: flex;
    flex-flow: column nowrap;

    &> div:last-child > div {
      margin-bottom: 0;
    }

  }

  .quoteAdvanced {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $gutter;

    &> div:last-child {
      grid-column: 1 / 3;
    }
  }
}

.quoteMessage {
  flex: 1;
}

.addQuoteButton {
  margin-left: 16px;
  margin-top: 16px;
}
