.navigation {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background-color: #F1F2F2;
  color: #101010;
  height: 40px;
  line-height: 16px;
  padding: 0px 8px;

  .addressBlock {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
}

.address {
  margin-left: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
