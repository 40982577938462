.layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-bottom: 40px;
}

.title {
  font-size: 24px;
  padding: 16px;
  text-align: center;
}
