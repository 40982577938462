@import '../../../../styles/variables';

.layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.title {
  font-size: 24px;
  padding: 16px;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: center;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 8px 40px 8px;
  text-align: center;

  :global {
    i.fa-check-circle {
      font-size: 64px;
      padding: 16px;
      color: $success;
    }

    i.fa-exclamation-triangle {
      font-size: 64px;
      padding: 16px;
      color: $warning;
    }
  }

  button {
    margin-top: 16px;
  }
}
