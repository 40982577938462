@import '../../styles/variables';

.root {
  padding: $gutter;
}

.sectionHeader {
  color: #993333;
  font-size: 14px;
  margin-bottom: 16px;
}

.empty {
  text-align: center;
}
