.title {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 48px;

  font-size: 2rem;

  span {
    font-size: 0.6em;
    display: block;
  }
}

.accountText {
  font-size: 16px;
}
