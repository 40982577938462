.layout {
  margin: 40px auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  h2 {
    font-size: 24px;
  }

  .tier {
    margin-top: 8px;
  }
}
