.padding {
  background: #f0f2f5;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.body {
  background: white;
  margin: 32px 32px 16px 32px;
  flex: 1;
}

.footer {
  text-align: center;
  background: #f0f2f5;
  margin: 0 32px 32px 32px;
  font-size: 14px;
}

.messageCard {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;

  &__message {
    text-align: center;
  }
}

@media all and (max-width: 575px) {
  .body {
    margin: 0;
  }

  .footer {
    margin: 8px 0;
  }
}
