.hideLabel {
  label {
    opacity: 0;
    pointer-events: none;
  }
}

.twoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.threeColumn {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
}

.errorComponent {
  border: solid 1px #cc0000;
  padding: 10px;
  margin-bottom: 20px;
  padding-bottom: 0;

  &> div {
    margin-bottom: 0;
  }
}

.termsGroup {
  position: relative;
  width: 425px;
}

@media all and (max-width: 768px) {
  .twoColumn {
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }

  .threeColumn {
    grid-template-columns: 1fr;
  }
}
