.layout {
  margin: 40px 8px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.title {
  font-size: 24px;
}

.info {
  text-align: center;
  margin-bottom: 32px;
}

.well {
  max-width: 600px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  color: #101010;
}

.explanation {
  margin: 40px auto;
  text-align: center;

  h4 {
    margin-bottom: 0;
    font-weight: 600;
  }

  a {
    font-weight: 600;
  }
}

.form {
  width: 300px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  :global(.ant-form), :global(.ant-form-item), :global(.ant-calendar-picker) {
    width: 100%;
  }
}

.twoColumn {
  display: flex;
  flex-flow: row nowrap;

  > * {
    flex: 1;
    margin: 40px;
  }
}
