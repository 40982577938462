@import '../../../styles/variables';

.spinnerContainer {
  flex: 1;
  display: flex;
  justify-content: center;
}

.layout {
  margin: 40px 40px 80px 40px;
}

.formContainer {
  &> div {
    margin-bottom: $gutter;
  }
}

.formActions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.header {

  span {
    color: #cc0000;
    display: block;
    font-size: 0.6em;
  }
}

.title {
  font-size: 2rem;
  text-align: center;

  span {
    font-size: 0.6em;
    display: block;
  }
}

.subtitle {
  font-size: 18px;
  font-weight: normal;
}

.accountText {
  font-size: 16px;
}

.confirmation {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-size: 16px;
  margin: 40px 0;

  .confirmationTitle {
    font-size: 2rem;
    text-align: center;
  }
}

.title {
  display: flex;
  align-items: baseline;

  .subtitle {
    font-size: 14px;
    color: $c-med-light;
    margin-left: 8px;
  }
}

@media all and (max-width: 768px) {
  .layout {
    margin: 16px;
  }
}

.lender {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid #999;
  border-radius: 4px;
  margin: 24px;
  padding: 12px;
}

.lenderInfo {
  display: flex;
  flex-flow: column nowrap;
  margin-left: 16px;
}

.lenderCompany {
  font-weight: 600;
  font-size: 16px;
}

.lenderLogo {
  width: 240px;
}
