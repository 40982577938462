.layout {
  background-color: #E4E4E6;
  text-align: center;
  min-height: calc(100vh - 64px);
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: white;

  > * {
    margin: 8px;
  }
}

$grid-size: 300px;
$grid-gap: 16px;

.container {
  max-width: ($grid-size * 3) + ($grid-gap * 2);
  margin: 16px auto 40px auto;
  display: flex;
  flex-flow: column nowrap;
}

.gridContainer {
  display: grid;

  grid-gap: $grid-gap;
  grid-template-columns: repeat(auto-fill, $grid-size);
  grid-auto-rows: $grid-size;
  justify-content: center;
  color: #444;

  > * {
    background-color: white;
    color: #101010;
    border-radius: 5px;
  }

  > :first-child {
    grid-row: 1 / 3;
  }
}

@media all and (max-width: 400px) {
  .container {
    margin: 16px;

    .gridContainer {
      grid-template-columns: 1fr;
    }
  }
}
