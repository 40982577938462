.layout {
  margin: 40px;
}

.stats {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-template-rows: repeat(auto-fill, 80px);
  margin-bottom: 40px;
}

.statLayout {
  .statLabel {
    margin-bottom: 4px;
    color: rgba(0,0,0,.45);
    font-size: 14px;
  }

  .statValue {
    color: rgba(0,0,0,.85);
    font-size: 24px;
  }
}
