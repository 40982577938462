@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.addressSelect {
  @include contractor-sidebar-container;
}

.addressSelect__content {
  @include contractor-sidebar-content;
}

.addressSelect__actions {
  @include contractor-sidebar-actions;
}
.addressSelect__list {
  flex: 1;
  overflow: auto;
}

.addressSelect__list__item {
  &> div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.addressSelect__title {
  @include contractor-sidebar-title;
}
