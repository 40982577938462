@import '../../../../styles/variables';

.container {
  width: 100%;
  max-width: 1400px;
  min-height: 100vh;
  background: #fff;
  padding: $gutter;
}

.content {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-bottom: $gutter;
  padding-bottom: $gutter;
  border-bottom: solid 1px #eee;
}

.info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  padding-left: 16px;

  &__value {
    font-weight: bold;
  }
}

.infoContent {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 16px;
  padding-top: 16px;
}

.infoFunding {
  width: 100%;
  margin-top: $gutter * 2;
  padding-top: $gutter;
  border-top: solid 1px #333;
}

.infoCalculator {
  width: 100%;
  padding-top: $gutter;
  border-top: solid 1px #333;
}

.map {
  width: 200px;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.sectionTitle {
  font-weight: bold;
  font-size: 1.5rem;
}

.projectReport {
  padding: 75px 0;
}

.title {
  margin-bottom: 0;

  span {
    font-size: 0.6em;
    display: block;
  }
}

.topHeader {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fcfcfc;
  border-bottom: solid 1px #eee;
  z-index: 999;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  padding: $gutter/2;

  &> *{
    margin-left: $gutter;
  }
}


@media all and (max-width: 768px) {
  .topHeader {
    justify-content: center;

    & > :not(:last-child) {
      margin-left: unset;
    }
  }

  .header {
    flex-flow: column nowrap;
    align-items: center;
  }

  .infoContent {
    flex-flow: column nowrap;
    align-items: center;
  }

  .info {
    padding-left: unset;
    margin-top: 16px;
  }

  .title {
    text-align: center;

    span {
      margin: 16px 0;
    }
  }
}


@media print {
  .projectReport {
    padding: unset;
  }

  .topHeader {
    display: none;
  }

  .infoFunding {
    page-break-before: always;
    margin: unset;
    padding: unset;
    border: 0;
  }
}
