.layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 40px;

  i {
    font-size: 24px;

    &:global(.fa-check) {
      color: #13BF81;
    }

    &:global(.fa-exclamation-triangle) {
      color: #FFBA5B;
    }

    &:global(.fa-times) {
      color: #FF6666;
    }
  }
}

.title {
  font-size: 24px;
}

.explanation {
  margin: 40px auto;
  text-align: left;
}

.well {
  max-width: 600px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  color: #101010;
}

.loadingContainer {
  .spinner {
    margin-top: 40px;
  }
}

.resultContainer {
  margin: 40px auto 8px auto;
}

.result {
  i {
    font-size: 72px;
  }
}

.resultRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 16px auto;
  width: 240px;

  .iconWrapper {
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
