@import "../../../styles/variables";

.content {
  padding: 16px;
  background: #fff;
}

.formBlock {
  margin-bottom: 16px;
}

.title {
  color: #666;
  margin-bottom: 16px;
}

@media all and (max-width: 575px) {
  .content {
    padding: 8px;
  }

  .formBlock {
    margin-bottom: 8px;
  }
}
