@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.actions {
  display: flex;

  &__error {
    @include transition;
    margin-right: $gutter/2;
    padding-right: $gutter;
    font-size: 0.8rem;
    color: #cc0000;
    opacity: 0;

    &__showing {
      opacity: 1;
    }
  }
}

.formContainer {
  &> form > div > div:nth-child(2) {
    //width: 50%;
    //max-width: 400px;
  }
}
