@import '../../../../styles/variables';

.layout {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}

.searchWrapper {
  text-align: center;
}

.list {
  display: flex;
  flex-flow: column nowrap;
  margin: 16px;
  flex: 1;

  border: 1px solid $c-light;
}

.success {
  path {
    fill: #008000;
  }
}

.error {
  path {
    fill: #ff6700;
  }
}

.listItem {
  padding: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid $c-light;
  }

  .icon {
    padding-left: 8px;
    padding-right: 16px;
    font-size: 24px;
  }

  .body {
    font-size: 14px;

    .address {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .cta {
    flex: 1;
    text-align: right;
  }
}
