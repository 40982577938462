.actions {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &__container {
    display: flex;
    flex-flow: row wrap;
    margin: -16px;

    > * {
      margin: 16px;
    }
  }
}

.filterField {
  width: 250px;
}

.resultsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    font-weight: bold;
    font-size: 1rem;
  }
}
