@import "../../styles/variables";

.collapseContainer {
  > div > div > div > div {
    padding: 0 !important;
    &> div {
      padding: $gutter/1.5;
      &:nth-child(odd) {
        background: #f3f3f3;
      }
    }
  }
}
