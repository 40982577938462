@import '../../../styles/variables';

.adminApplicationList {

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: $gutter/2;
  }

  &__filter {
    width: 300px !important;
    margin-left: auto !important;
  }
}
