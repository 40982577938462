.fundingOptions {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.fundingOption {
  border: 1px solid #993333;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 16px;
  padding: 8px;
  width: 300px;
  height: 480px;

  &.unavailable {
    background-color: #eeeeee;
    border: 1px solid #333333;
  }

  .name {
    font-size: 24px;
  }

  .description {
    font-size: 12px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .rate {
    font-size: 18px;
    margin-left: 8px;
  }

  .availability {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 80px;

    .availabilityTitle {
      font-size: 18px;
    }

    .availabilityMessage {
      font-size: 16px;
      font-weight: 300;
      line-height: 16px;
      text-align: center;
      margin-top: 8px;
    }
  }
}

.fundingOptionRow {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin-top: 8px;
  align-items: center;
  justify-content: space-between;
  text-align: left;

  padding-top: 8px;
  border-top: 1px solid #cccccc;

  .fundingOptionRowHeader {
    display: flex;
    flex-flow: column nowrap;
    font-size: 16px;
  }

  .fundingOptionRowName {
    font-weight: 600;
  }

  .fundingOptionRowSubtext {
    font-size: 14px;
  }

  i {
    font-size: 24px;
    margin-top: 2px;
    margin-left: 8px;
  }

  :global {
    .fa-check {
      color: #4BB543;
    }

    .fa-exclamation-triangle {
      color: #ff9933;
    }

    .fa-times {
      color: #cc0000;
    }
  }
}


@media all and (max-width: 400px) {
  .fundingOption {
    width: unset;
    height: unset;
  }
}

@media print {
  .fundingOptions {
    display: table;
    text-align: center;
  }

  .fundingOption {
    display: inline-flex;
    page-break-before: always;
    page-break-inside: avoid;
  }
}
