@import '../../styles/variables';

.root {
  max-width: 900px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: $gutter * 2;
  }

  .rowHeader {
    margin-right: 24px;
    margin-bottom: $gutter / 2;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .rowContent {
    flex: 1;
  }
}

.rateRow, .riskRow {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .rateRowHeader {
    flex: 0 250px;
    margin-right: 24px;
  }

  .riskRowHeader {
    flex: 0 120px;
    margin-right: 24px;
    font-weight: 600;
    text-align: right
  }

  .rateRowBody {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    > *:not(input) {
      text-align: center;
      flex: 1;
    }

    > *:first-child {
      margin-right: 24px;
    }
  }
}

.riskRow {
  &__reset {
    cursor: pointer;

    button {
      border-color: #fff;
    }
  }
}

.inputContainer {
  position: relative;
}

.errorMessage {
  position: absolute;
  color: #ddd;
  font-style: italic;
  bottom: -20px;
  right: 0;
  font-size: 12px;
}

.prepaymentTermsSelect {
  width: 100%;
}

.propertyTypes {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}

.radioGroup {
  flex: 1;
  display: flex !important;
  flex-flow: column nowrap;
}
