.personaTile {
  border: 1px solid #993333;
  margin: 16px 0;
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:hover {
    background-color: #ffebeb;
  }

  .personaTileIconWrapper {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 40px;
    }
  }

  .personaTileMain {
    margin-left: 8px;
    flex: 1;

    .personaTileTitle {
      font-weight: 600;
    }

    .personaTileText {
      line-height: 18px;
    }
  }
}

.subnav {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  .subnavTitle {
    font-weight: 600;
  }

  .subnavBacklink {
    font-size: 12px;
  }
}
