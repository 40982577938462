.formLayout {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  margin: 24px;
}

.formActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formTitleContainer {
  font-size: 2rem;
  display: block;
  color: #000;
  margin-bottom: 30px;

  .formSubtitle {
    font-size: 0.6em;
    display: block;
  }
}

.hideLabel {
  label {
    opacity: 0;
    pointer-events: none;
  }
}

.progressBar {
  width: 100%;
  height: 20px;
  border-radius: 15px;
  background:#eee;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.progressBarInner {
  transition: width 0.3s ease-out;
  color: #fff;
  position: absolute;
  height:inherit;
  background: #993333;
  border-radius: 15px;
  font-size: 0.8rem;

  &:before {
    content: attr(data-completed) "%";
    position: absolute;
    display: block;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.stepComponentContainer {

}

.title {
  margin-top: 30px;
}

.twoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.threeColumn {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
}
