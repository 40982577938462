@import '../../../styles/variables';
@import '../../../styles/mixins';

.fundingOptions {
  @include contractor-sidebar-container;
  position: relative;

  &__actions {
    @include transition;
    @include  contractor-sidebar-actions;

    &__buttons {
      &> button {
        margin-right: $gutter/2;
      }
    }

    &__floating {
      box-shadow: 0 5px 20px 0 rgba(0,0,0,0.25);
    }
  }

  &__chart__container {
    width: 100%;
    max-width: calc(1000px + #{$gutter});
    justify-items: center;
    text-align:center;
    background: rgba($brand-primary, 0.4);
    color:$brand-primary-opposite;
    box-sizing: content-box !important;
    margin: 0 auto;
    position: relative;
    margin-top: 50px;
  }

  &_chartScrollContainer {
    width: 750px;
    overflow: hidden;
    background: #fff;

    &> div {
      transition: all 0.3s ease-out;
      display: grid;
      grid-template-columns: repeat(7, 250px);
    }
  }

  &__content {
    @include contractor-sidebar-content;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__notAvailable {
      -webkit-filter: blur(5px);
      -moz-filter: blur(5px);
      -o-filter: blur(5px);
      -ms-filter: blur(5px);
      filter: blur(5px);
      opacity: 0.2;
      pointer-events: none;
    }
  }

  &__legend {
    max-width: 1030px;
    width: 100%;
    padding-left: 200px;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;

    &__notAvailable {
      -webkit-filter: blur(5px);
      -moz-filter: blur(5px);
      -o-filter: blur(5px);
      -ms-filter: blur(5px);
      filter: blur(5px);
      opacity: 0.2;
    }

    &__option {
      margin-left: $gutter/2;

      i {
        margin-right: 5px;
      }
    }
  }

  &__navigation {
    position: absolute;
    top: -50px;
    right: 0;
    display: flex;
    max-width: 750px;
    width: calc(100% - 200px);
    justify-content: space-between;

    &__disabled {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__notOptionsMessage {
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translate(-50%, 0);

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
    }
  }

  &__option {
    display: grid;
    grid-template-rows: 35px 35px 1fr;
    width: 100%;

    &__rate {
      font-size: 2rem;
      line-height: 1;
    }

    &:nth-child(3) {
      background: $brand-primary;
    }

    &> div {
      padding: 10px;
      line-height: 1;

      &:last-child {
        padding-bottom: 10px;
      }
    }
  }
}



.fundingOptions__content__title {
  text-align:center;
  font-size: 2rem;
  margin-bottom: $gutter;
}

.fundingOptions__content__container {
  width: 100%;
}

.fundingOptions__point {
  display: grid;
  grid-template-columns: 300px 750px;

  &:nth-child(odd) {
    background: #f6f6f6;
  }

}

.fundingOptions__point__label {
  padding: 10px;
  position: relative;
  svg {
    margin-right: 10px;
    cursor: pointer;
  }
}

.fundingOptions__chart {
  display: flex;
  //display: grid;
  //grid-template-columns: 300px 600px;
  //justify-items: center;
}

.fundingOptions__chart__label {
  i {
    margin-right: $gutter/2;
  }
}

.fundingOptions__chart__column {
  display: grid;
  width: 100%;
  grid-template-rows: 50px 115px 50px repeat(8, 50px) 60px 60px;
  align-items: center;
  position: relative;

  &> div {
    @include transition;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-bottom: solid 1px rgba(#862d2d, 0.2);

    &.fundingOptions__chart__bestMatch {
      background: #fff !important;
      color: $brand-primary;
      width: 200%;
      transform: translateX(-25%);
      font-size: 1.3rem;
      position: relative;

      &__active {
        z-index: 99;
      }
    }
  }

  &:not(.fundingOptions__chart__column__active) {
    background-color: rgba($brand-primary, 0.4);
    &:nth-child(n+2):hover {
      & > div:not(.fundingOptions__chart__bestMatch) {
        background: rgba($brand-primary, 0.6) !important;
        border-bottom: solid 1px rgba(#862d2d, 0.5);
      }
    }
  }
}

.fundingOptions__chart__column__active {
  box-shadow: 0 5px 20px 0 rgba(0,0,0,0.25);
  position: relative;
  z-index: 9;
  &> div:not(.fundingOptions__chart__bestMatch):not(.fundingOptions__chart__column__blank) {
    background: rgba($brand-primary, 0.8) !important;
    border-bottom: solid 1px rgba(#862d2d, 0.5);
  }
}

.fundingOptions__chart__column__labels {
  justify-items: start;
  background: #993333;
  min-width: 200px;

  &> div {
    padding: 0 10px;
    justify-content: flex-start;
    border-bottom: solid 1px rgba(#862d2d, 0.8);

    &:nth-child(n + 4) {
      background: $brand-primary;
    }
  }
}

.fundingOptions__chart__column__blank {
  background-color: #fff !important;
  border-bottom: none !important;
}

.fundingOptions__chart__column__rate {
  position: relative;

  //&> span {
  //  display: flex;
  //  width: 100%;
  //  justify-content: center;
  //  position: absolute;
  //  top: 50%;
  //  left: 50%;
  //  transform: translate( -50%, -50%);
  //}

  &__button {
    position: relative;

    &:after {
      content: '';
      height: 100%;
      width: calc(100% + 40px);
      background: #fff;
      position: absolute;
      top: 0;
      left: -20px;
      z-index: 1;
    }

    button {
      margin-top: 10px;
      position: relative;
      z-index: 2;
    }
  }
}

.fundingOptions__chart__title {
  font-weight: bold;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.fundingOptions__chart__description {
  font-size: 10px;
  font-weight: unset;
}
.fundingOptions__chart__available {
  position: relative;

  i {
    margin-right: $gutter/3;
  }
}

@mixin optionIcon {
  i, span {
    position: relative;
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.check {
  @include optionIcon;
  path {
    fill: #4BB543;
  }
  svg {
    width: 20px;
    height: 20px;
  }
}

.warning {
  @include optionIcon;
  position: relative;
  path {
    fill: #ff9933;
  }
  svg {
    width: 20px;
    height: 20px;
  }
}

.error {
  @include optionIcon;
  position: relative;
  path {
    fill: #cc0000;
  }
  svg {
    width: 20px;
    height: 20px;
  }
}

.actions {
  button {
    margin: 4px;
  }
}

.responsiveLayout {
  display: none;
}

@media all and (max-width: 1078px) {
  .responsiveLayout {
    display: flex;
  }

  .staticLayout {
    display: none;
  }
}
