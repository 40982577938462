.layout {
  padding: 40px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.title {
  font-size: 21px;
}

.subtitle {
  max-width: 470px;
  text-align: center;
  margin-bottom: 24px;
}

.contactInfoForm {
  padding: 8px 0;
  width: 300px;
}
