@import '../../../styles/variables';

.breakdown {
  //display: grid;
  //grid-template-columns: 225px 1fr;
  //grid-gap: $gutter/2;
}

.propertyInfo {
  display: grid;
  grid-template-columns: 50px 1fr;
  position: relative;
  box-sizing: content-box;

  &__label {
    background: $brand-primary;
    color: #fff;
    width: 100%;
    text-align: center;
    padding: $gutter/3 $gutter/2;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    line-height: 1;
    font-size: 1.3rem;
  }
  img {
    width: 175px;
    height: 175px;
  }
}
.propertyMetrics {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  grid-gap: 16px;

  &__block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    border: solid 1px #333;
    box-shadow: 0 3px 5px rgba(0,0,0,0.15);
    padding: $gutter/3;
    width: 100%;
    height: 100%;
  }

  &__blockContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__label {
    grid-column: 1/3;
    font-weight: bold;
  }

  &__price {
    grid-column: 1/3;
    font-size: 2.2rem;
    line-height: 1;

    sup {
      font-size: 0.3em;
      vertical-align: super;
    }
  }

  &__secondary {
    font-size: 0.8rem;
  }

  &__tooltip {
    position: absolute;
    top: 3px;
    right: 8px;
  }
}

@media all and (max-width: 400px) {
  .propertyMetrics {
    grid-template-columns: 1fr;
  }
}
