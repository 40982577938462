.layout {
  margin: 40px auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;

  max-width: 600px;

  p {
    margin: 0;
  }
}

.codeBlock {
  margin: 32px 0;

  .code {
    font-size: 32px;
    letter-spacing: 2px;
    color: #993333;
  }
}
