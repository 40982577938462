@import "../../styles/variables";

.form {
  max-width: 300px;
  margin: $gutter;

  &__large {
    max-width: 450px;
  }

  [role="tab"] {
    font-size: 16px !important;
  }
}

.formActions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.logo {
  width: 60%;
  margin-left: 20%;
  margin-bottom: 16px;
}
