@import '../../../../styles/variables';

.layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 8px;
  padding-bottom: 40px;
}

.title {
  font-size: 24px;
  padding: 16px;
  text-align: center;
}

.description {
  max-width: 600px;
}

.options {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.option {
  padding: 24px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  i {
    font-size: 48px;
    margin-bottom: 12px;
  }
}
