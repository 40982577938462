.layout {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.title {
  font-size: 21px;
}

.text {
  padding: 16px;
  text-align: center;
}
