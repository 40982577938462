$brand-primary: #993333;
$brand-primary-opposite: #ffffff;
$gutter: 30px;

$success: #13BF81;
$warning: #FFBA5B;
$error: #FF6666;
$info: #0095FF;

$c-darkest: #021018;
$c-dark: #142955;
$c-med-dark: #4E5E7F;
$c-med-light: #8994AA;
$c-light: #C4C9D4;

$c-light-border: #E4E4E6;
$c-heavy-border: #DADDDF;

$c-background-grey: #EFF1F2;

$c-text-label: #6E7178;
$c-text-value: #2A2D35;

$transition-prop: all;
$transition-duration: 0.3s;
$transition-ease: ease-out;

$lg-breakpoint: 1200px;
$md-breakpoint: 1028px;
$sm-breakpoint: 768px;
