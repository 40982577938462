@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.checkApproval__checkList {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 16px;
  width: fit-content;
  padding: 16px 0;

  &__item {
    display: flex;
    margin-bottom: 8px;
    text-align: left;

    &__icon {
      margin-right: $gutter/2;

      path {
        fill: #ff6700;
      }
    }

  }
}

.checkApproval__overall {
  width: 160px;
  height: 160px;
  border: solid 10px #ff6700;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  align-self: center;

  i {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 85px;
    line-height: 85px;

    path {
      fill: #ff6700;
    }
  }

  &__success {
    border-color: #368230;
    color: #368230;

    i {
      margin-top: 5px;
    }
    path {
      fill: #368230 !important;
    }
  }
}

.checkApproval__projectSize {
  margin: $gutter/2 0;
  text-align: center;
  font-size: 18px;
}

.checkApproval__legalAddress {
  margin: $gutter/2 0;
  text-align: center;
  font-size: 14px;
}

.checkApproval__content {
  display: grid;
  grid-template-rows: max-content max-content 1fr;
  padding: 0 16px;
}

.checkApproval__content__active {
  transform: translateY(0);
}

.checkApproval__chart {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 400px;

  text {
    font-size: 14px !important;
  }
}

.checkApproval__errros {
  margin-top: 10px;
  line-height: 1.4;
}

.checkApproval__title {
  margin: 0;
  line-height: 1;
  height: auto;
  font-size: 32px;
  text-align: center;

  .title {
    color: #101010;
    font-size: 21px;
    line-height: 1.3;
    margin: 0;
  }

  .subtitle {
    font-style: italic;
    font-size: 14px;
  }
}

.checkApproval__loader__container {
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
}

.checkApproval__loader__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -25px;

  svg {
    width: 100px;
    height: 100px;
  }
  path {
    fill: #ff6700;
  }
}

.success {
  path {
    fill: #368230;
  }
}

.checkApproval__infoCopy {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.contentContainer {
  @include transition;
  opacity: 0;
  pointer-events:none;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.contentContainer__active {
  opacity: 1;
  pointer-events: auto;
}

.loadingContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0 8px;

  .spinner {
    padding: 16px 0;
  }
}

.speedometerContainer {
  height: 180px;
}
