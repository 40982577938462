.layout {
  display: flex;
  flex-flow: column nowrap;
  padding: 16px;
  width: 600px;
  max-width: 100%;
  margin: 0 auto;

  :global(.ant-progress-bg) {
    height: 32px !important;
  }

  :global(.ant-progress-inner) {
    background-color: #eaeaea;
    border-radius: unset;
  }

  :global(.ant-progress-bg) {
    background-color: #c1b1b1;
    border-radius: unset;
  }

  h2 {
    margin-bottom: 0;
  }
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;

  :global {
    .ant-progress-text {
      font-size: 12px;
      color: #333;
      font-weight: 600;
    }
  }
}

.nextAction {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid #c5c5c5;

  .nextActionText {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .nextActionTitle {
    font-weight: 600;
  }

  .nextActionMessage {
    margin-right: 16px;
    font-style: italic;
  }
}

@media all and (max-width: 499px) {
  .layout {
    h2 {
      font-size: 18px;
    }
  }
}
