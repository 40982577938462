@import '../../../styles/variables';

.feedbackButton {
  position: fixed !important;
  bottom: $gutter;
  right: $gutter;
  z-index: 99;
  transform: scale(1.2,1.2);
}

@media all and (max-width: 575px) {
  .feedbackButton {
    display: none !important;
  }
}
