.layout {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-width: 100%;
  background: #f0f2f5;
  flex-flow: column nowrap;
}

.header {
  font-weight: 400;
  font-size: 16px;
}

.message {
  font-weight: 300;
  font-size: 16px;
  max-width: 300px;
}

.from {
  text-align: right;
}

.button {
  margin-top: 16px;
}
