:global {
  @keyframes :global(fadeIn) {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
