@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.section {
  margin-bottom: 32px;
}

.title {
  font-size: 18px;
  margin: 0 0 8px 0;
  font-weight: 500;
}

.layout {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__inputs {
    font-size: 0.8rem;
    background: #fff;
    border: solid 1px #333;

    &__rowHeader {
      border-bottom: solid 1px #999;
    }

    &__row {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 10px;
      align-items: center;

      &__header {
        i {
          margin-left: 4px;
        }
      }

      & > div {
        @include transition;

        padding: $gutter / 3;
        opacity: 1;
      }

      &:nth-child(even) {
        background-color: #eee;
      }
    }
  }

  &__loading {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__title {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: $gutter;

    .applicationSubtitle {
      display: block;
      font-size: 0.8em;
      font-style: italic;
    }
  }

  &__dirtyField {
    opacity: 0.35 !important;
  }

  &__input {
    max-height: 30px !important;
    font-size: 12px !important;
    padding: 7px !important;
  }

  &__select {
    & > div {
      min-height: 20px !important;
    }
  }

  &__option {
    font-size: 12px;
  }

  &__values {
    &__null {
      color: #ccc;
      font-style: italic;
    }
  }
}
