.root {
  display: flex;
  flex-flow: column nowrap;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 40px;
  }
}

.leftContent {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .map {
    width: 150px;
    height: 150px;
    margin-right: 8px;
    border: 1px solid black;
  }
}

.rightContent {
  flex: 1;

  .statsColumns {
    display: flex;
    flex-flow: row nowrap;

    .statsColumn {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
    }
  }
}

.propertyName {
  margin-bottom: 0px;
}

.rate {
  :global(.ant-rate-star) {
    margin-right: 0px;
  }
}

.map {
  width: 200px;
  height: 200px;
}

.quote {
  display: flex;
  flex-flow: row nowrap;
  margin: 16px;

  &:last-child {
    margin-bottom: 0px;
  }

  > *:not(:last-child) {
    margin-right: 24px;
  }

  .quoteHeader {
    flex: 0 0 120px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .quoteHeaderLabel {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }
  }

  .quoteField {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 8px;

    > div {
      flex: 1;
    }
  }

  .quoteBasics {
    flex: 0 0 150px;
    display: flex;
    flex-flow: column nowrap;

    label {
      min-width: 50px;
    }
  }

  .quoteAdvanced {
    flex: 0 0 250px;
    display: flex;
    flex-flow: column nowrap;

    label {
      min-width: 100px;
    }
  }

  .quoteMessage {
    flex: 1;

    textarea {
      height: 100%;
    }
  }
}
