.layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}

.title {
  font-size: 24px;
  padding: 16px;
  text-align: center;
}

.disclaimer {
  text-align: center;
  font-style: italic;
  max-width: 720px;
  font-size: 14px;
}
