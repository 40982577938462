.layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  i {
    font-size: 36px;
    color: red;
  }

  .text {
    color: red;
  }
}
