@import '../../../../styles/variables';

.layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 8px;
  padding-bottom: 40px;
}

.title {
  font-size: 24px;
  padding: 16px;
  text-align: center;
}

.description {
  max-width: 600px;
}

.contractorList {
  margin: 24px 0;
  max-width: 900px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.invitedLayout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 auto;
  padding: 40px;


  .invitedTitle {
    font-size: 21px;
    text-align: center;
  }

  .invitedText {
    text-align: center;
    max-width: 600px;
    padding-top: 16px;
  }

  :global {
    i.fa-check-circle {
      font-size: 64px;
      padding: 16px;
      color: $success;
    }
  }

  button {
    margin-top: 16px;
  }
}

.scopeOfWorkError {
  text-align: center;
  padding-bottom: 16px;
  font-style: italic;
}
